import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Elements from '../../../styles/Elements.module.css'


const AppLayout = (props) => {

	const systemUser = props.systemUser
	const signOut = props.signOut

	return (
		<>
			<div className={Elements.sticky} style={{ top: "0rem", background: "#121212" }}>
				<Header systemUser={systemUser} signOut={signOut} />
			</div>
			<Outlet />
		</>
	)
}

export default AppLayout