import React from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../../../../styles/Text.module.css'
import Buttons from '../../../../styles/Buttons.module.css'
import ContestUserPicks from '../contest-user-displays/ContestUserPicks'
import ContestAffiliationDisplay from '../ContestAffiliateDisplay'

function CompletedContest(props) {

	const contest = props.contest
	const navigate = useNavigate()

	function onContestClick() {
		navigate('/completedcontest/' + contest.contestId)
	}

	return (
		<div style={{ backgroundColor: "rgba(255, 255, 255, 0.08)", padding: "1rem", borderRadius: ".5rem" }} onClick={onContestClick}>
			<div className={Text.textLarge}>{contest.friendlyContestName}</div>
			<div style={{ margin: ".5rem 0" }}>
				<div className={Text.labelSmall}>{"Started " + new Date(contest.startDateTime).toLocaleString("en-US")}</div>
				<div className={Text.labelSmall}>{"Ended " + new Date(contest.stopDateTime).toLocaleString("en-US")}</div>
			</div>
			{contest.affiliations &&
				<div style={{ height: "6rem" }}>
					<ContestAffiliationDisplay affiliations={contest.affiliations} contest={contest} />
				</div>
			}
			{/* <div style={{ margin: "1rem 0" }}>
				<div className={Text.labelSmall}>{"Entries " + contest.participantCount + "/" + contest.participantLimit}</div>
			</div> */}
			<div style={{ margin: "1rem 0 0 0", display: "flex", gap: "1rem", justifyContent: "center" }}>
				<div className={Text.labelMedium}>{"Rank " + (contest.isRankTie ? "T" : " ") + contest.rank}</div>
				<div className={contest.percentDelta > 0 ? Text.textPrimaryMedium : Text.labelMedium}>{(contest.percentDelta * 100).toFixed(2) + "%"}</div>
			</div>
			<div style={{ margin: ".5rem" }}>
				<ContestUserPicks picks={contest.picks} align={"center"} />
			</div>
			<div style={{ margin: "1.5rem 0 1rem 0" }}>
				<div className={Buttons.buttonPrimaryRounded} style={{ width: "10rem", margin: "auto" }}>View Details</div>
			</div>
		</div >
	)
}

export default CompletedContest