import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getContest } from "../../../../api/ContestApi"
import Text from '../../../../styles/Text.module.css'
import Buttons from '../../../../styles/Buttons.module.css'

function JoinContestSuccess() {

  const navigate = useNavigate()
  const { contestId } = useParams()
  const [contest, setContest] = useState(null)

  useEffect(() => {
    loadContest()
  }, [])

  async function loadContest() {
    try {
      const res = await getContest(contestId)
      console.log(res)
      if (res) {
        setContest(res.contest)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  function onContinueClick() {
    navigate("/contests/upcoming/")
  }

  if (contest) {
    return (
      <div>
        <div style={{ display: "grid", alignContent: "center", alignItems: "center", margin:"3rem 0" }}>
          <div>
            <div className={Text.textLarge}>Wheels Up</div>
            <div className={Text.textMedium} style={{ margin: ".5rem 0 1rem 0" }}>Your Contest starts at</div>
            <div className={Text.textPrimaryLarge}>{new Date(contest.startDateTime).toLocaleString("en-US")}</div>
          </div>
        </div>
        <div>
          <div style={{ margin: "2rem auto", maxWidth:'20rem' }}>
            <div className={Buttons.buttonPrimary} onClick={onContinueClick}>Got it</div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div>Loading</div>
    )
  }
}

export default JoinContestSuccess