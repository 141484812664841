import React from "react"
import Text from '../../../../styles/Text.module.css'
import { getOrdinalSuffix } from "../../../../helpers/Numbers"

const CurrentUserContestUser = (props) => {

	const contestUser = props.contestUser
	const contest = props.contest

	const getRank = (rank, isRankTie) => {
		let rankString = ""
		if (isRankTie) rankString += "T"
		rankString += rank
		return rankString + getOrdinalSuffix(rank)
	}

	return (
		<div style={{ display: "grid", gridTemplateColumns: "3rem 7rem 1fr auto", justifyItems: "left", alignItems: "center" }}>
			<div className={Text.labelLarge}>{getRank(contestUser.rank, contestUser.isRankTie)}</div>
			<div className={contestUser.percentDelta > 0 ? Text.textLargePositive : Text.textLargeNegative}>{(contestUser.percentDelta * 100).toFixed(2) + "%"}</div>
			<div></div>
			<div>
				<div className={Text.textSmall}>{"S&P 100"}</div>
				<div className={contest.index.percentDelta > 0 ? Text.textSmallPositive : Text.textSmallNegative}>{(contest.index.percentDelta * 100).toFixed(2) + "%"}</div>
			</div>
		</div>
	)
}

export default CurrentUserContestUser