import React, { useState } from 'react'
import jetImage from '../../../assets/big-jet.png'
import Text from '../../../styles/Text.module.css'
import { useNavigate } from 'react-router-dom'
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

function MenuModal(props) {
	const systemUser = props.systemUser
	const signOut = props.signOut
	const isMenuOpen = props.isMenuOpen
	const setIsMenuOpen = props.setIsMenuOpen

	const navigate = useNavigate()

	const modalStyle = {
		position: 'absolute',
		left: 0,
		right: 0,
		textAlign: "center",
		width: '100%',
		height: '100%',
		maxWidth: '50rem',
		margin: "auto",
		backgroundColor: "black"
	}

	const onClick = (path) => {
		setIsMenuOpen(false)
		navigate(path)
	}

	return (
		<Modal open={isMenuOpen} onClose={() => setIsMenuOpen(false)} aria-labelledby="Menu" aria-describedby="Main Menu">
			<div style={modalStyle}>
				<div>
					<div style={{ marginLeft: "auto", width: "15rem", minHeight: "20rem", textAlign: "right", padding: "1rem" }}>
						<CloseIcon fontSize="large" onClick={() => setIsMenuOpen(false)} />
						<div className={Text.textLarge}>
							<div style={{ marginBottom: "1.5rem" }} onClick={() => onClick('/contests')}>Contests</div>
							<div style={{ marginBottom: "1.5rem" }} onClick={() => onClick('/profile')}>Investor Profile</div>
							<div style={{ marginBottom: "1.5rem" }}>Settings</div>
							<div style={{ marginBottom: "1.5rem" }}>About</div>
							<div style={{ marginBottom: "1.5rem" }} onClick={() => signOut()}>Log Out</div>
						</div>
					</div>
					<div>
						<div style={{ textAlign: "right", overflow: "hidden" }}>
							<img src={jetImage} alt={""} style={{ position: "relative", right: "-8rem" }} />
						</div>
						<div style={{ marginTop: "5rem", textAlign: "right", overflow: "hidden" }}>
							<img src={jetImage} alt={""} style={{ position: "relative", left: "-2rem" }} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default MenuModal