import React, { useState } from "react"
import { initializeUser, updateUserTicker } from "../../../api/UserApi"
import Text from '../../../styles/Text.module.css'
import Buttons from '../../../styles/Buttons.module.css'


function InitializeUser(props) {

  const setSystemUser = props.setSystemUser
  const [ticker, setTicker] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [affiliation, setAffiliation] = useState(null)

  async function onInitializeClick() {

    if (!ticker || !firstName || !lastName) {
      console.log("invalid info")
      return
    }

    try {
      const res = await initializeUser(firstName, lastName, ticker, affiliation)
      if (res) {
        setSystemUser(res.user)
      }
    }
    catch (err) {

    }
  }

  return (
    <div style={{ margin: "1rem 0" }}>
      <div className={Text.textLarge}>Welcome to Top Gun Investors</div>
      <div style={{ margin: "1rem 0", textAlign: "left" }}>
        <div style={{ margin: "1rem 0" }}>
          <div className={Text.textMedium}>{"Enter your ticker (5 letters max)"}</div>
          <div className={Text.labelSmall} style={{ marginBottom: ".25rem" }}>This is how you will be identified by others on the app</div>
          <input type="text" maxLength={5} value={ticker} onChange={(e) => setTicker(e.target.value)} />
        </div>
        <div style={{ margin: "1rem 0" }}>
          <div className={Text.textMedium}>{"Enter your first name"}</div>
          <input type="text" style={{ width: "100%" }} maxLength={50} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div style={{ margin: "1rem 0" }}>
          <div className={Text.textMedium}>{"Enter your last name"}</div>
          <input type="text" style={{ width: "100%" }} maxLength={50} value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div style={{ margin: "1rem 0" }}>
          <div className={Text.textMedium}>{"Select the group you represent"}</div>
          <div style={{ margin: ".5rem 1rem" }}>
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "1rem", margin: ".5rem" }} onClick={(e) => setAffiliation(null)}>
              <input type="radio" value={null} checked={affiliation === null} onChange={(e) => setAffiliation(null)} />
              <div className={Text.textMedium}>None</div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "1rem", margin: ".5rem" }} onClick={(e) => setAffiliation("ru")}>
              <input type="radio" value={"ru"} checked={affiliation === "ru"} onChange={(e) => setAffiliation(e.target.value)} />
              <div className={Text.textMedium}>Rutgers</div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "1rem", margin: ".5rem" }} onClick={(e) => setAffiliation("osu")}>
              <input type="radio" value={"osu"} checked={affiliation === "osu"} onChange={(e) => setAffiliation(e.target.value)} />
              <div className={Text.textMedium}>Ohio State</div>
            </div>
          </div>
        </div>
        <div className={Buttons.buttonPrimary} style={{ margin: "2rem 0" }} onClick={onInitializeClick}>Initialize Account</div>
      </div>
    </div>
  )
}

export default InitializeUser