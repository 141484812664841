import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const getContestUserPicks = async (contestUserId) => {
  let response = await fetch(getEndpoint() + "contest-user-pick/get-contest-user-picks", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestUserId: contestUserId
    })
  });

  response = await response.json();
  return response
}
