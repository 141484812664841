import React, { useEffect, useState } from 'react'
import { getUserCompletedContests } from '../../../../api/ContestApi'
import CompletedContest from './CompletedContest'
import Text from '../../../../styles/Text.module.css'

function CompletedContests(props) {

  const [contests, setContests] = useState(null)

  useEffect(() => {
    loadContests()
  }, [])

  async function loadContests() {
    try {
      const res = await getUserCompletedContests()
      if (res) {
        setContests(res.contests)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  if (!contests) {
    return (
      <div>Loading</div>
    )
  }

  return (
    <div>
      {contests.length < 1 ?
        <div>
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.08)", padding: "1rem", borderRadius: ".5rem" }} >
            <div className={Text.textLarge}>No Completed Contests</div>
          </div>
        </div> :
        <div>
          {contests && contests.map((contest) =>
            <div key={contest._id} style={{ margin: "0 0 .5rem 0" }}>
              <CompletedContest contest={contest} />
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default CompletedContests