import React, { useEffect, useState } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { Route, Routes } from 'react-router-dom';
import UpcomingContests from './components/app/contests/upcoming-contests/UpcomingContests';
import JoinContestInfo from './components/app/contests/join-contest/JoinContestInfo';
import JoinContestPicks from './components/app/contests/join-contest/JoinContestPicks';
import JoinContestSuccess from './components/app/contests/join-contest/JoinContestSuccess';
import { getUser } from './api/UserApi';
import InitializeUser from './components/app/initialize-user/InitializeUser';
import ActiveContestPage from './components/app/contests/active-contests/ActiveContest';
import CompletedContests from './components/app/contests/completed-contests/CompletedContests';
import CompletedContestPage from './components/app/contests/completed-contests/CompletedContestPage';
import ActiveContest from './components/app/contests/active-contests/ActiveContest';
import AppLayout from './components/app/app-layout/AppLayout';
import ContestsLayout from './components/app/contests-layout/ContestsLayout';
import UserProfile from './components/app/user-profile/UserProfile';
import ChangeContestPicks from './components/app/contests/change-contest-picks/ChangeContestPicks';
import ChangeContestPicksSuccess from './components/app/contests/change-contest-picks/ChangeContestPicksSuccess';

Amplify.configure(awsExports);

function App({ signOut, user }) {

  const [systemUser, setSystemUser] = useState(null)

  useEffect(() => {
    loadUser()
  }, [])

  async function loadUser() {
    try {
      const res = await getUser()
      if (res) {
        setSystemUser(res.user)
        console.log(res.user)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  //check if system user exists and set ticker if not set
  if (!systemUser) {
    return (
      <div>Loading User</div>
    )
  }
  else if (systemUser.status === "not-initialized") {
    return (
      <div className="App">
        <InitializeUser setSystemUser={setSystemUser} />
      </div>
    )
  }

  return (
    <div className="App">
      <Routes>
        <Route element={<AppLayout systemUser={systemUser} signOut={signOut} />}>
          <Route path="/contests" element={<ContestsLayout />}>
            <Route path="upcoming" element={<UpcomingContests />} />
            <Route path="active" element={<ActiveContest />} />
            <Route path="completed" element={<CompletedContests />} />
            <Route path="" element={<UpcomingContests />} />
          </Route>
          <Route path="/joincontestinfo/:contestId" element={<JoinContestInfo />}></Route>
          <Route path="/joincontestpicks/:contestId" element={<JoinContestPicks />}></Route>
          <Route path="/joincontestsuccess/:contestId" element={<JoinContestSuccess />}></Route>
          <Route path="/changecontestpicks/:contestId" element={<ChangeContestPicks />}></Route>
          <Route path="/changepickssuccess/:contestId" element={<ChangeContestPicksSuccess />}></Route>
          <Route path="/activecontest/:contestId" element={<ActiveContestPage systemUser={systemUser} />}></Route>
          <Route path="/completedcontest/:contestId" element={<CompletedContestPage systemUser={systemUser} />}></Route>
          <Route path="/profile" element={<UserProfile systemUser={systemUser} />}></Route>
        </Route>
        <Route element={<AppLayout systemUser={systemUser} signOut={signOut} />}>
          <Route path="/" element={<ContestsLayout />}>
            <Route path="" element={<UpcomingContests systemUser={systemUser} />} />
          </Route>
        </Route>
      </Routes>
      {/* <Routes>
        <Route path="/app" element={<MainPage systemUser={systemUser} signOut={signOut} />}>
          <Route path="upcoming" element={<UpcomingContests systemUser={systemUser} />} />
          <Route path="active" element={<ActiveContest systemUser={systemUser} />} />
          <Route path="completed" element={<CompletedContests systemUser={systemUser} />} />
          <Route path="" element={<UpcomingContests systemUser={systemUser} />} />
        </Route>
        <Route path="/joincontestinfo/:contestId" element={<JoinContestInfo />}></Route>
        <Route path="/joincontestpicks/:contestId" element={<JoinContestPicks />}></Route>
        <Route path="/joincontestsuccess/:contestId" element={<JoinContestSuccess />}></Route>
        <Route path="/activecontest/:contestId" element={<ActiveContestPage systemUser={systemUser} />}></Route>
        <Route path="/completedcontest/:contestId" element={<CompletedContestPage systemUser={systemUser} />}></Route>
        <Route path="/profile" element={<UserPage systemUser={systemUser} />}></Route>
        <Route path="/" element={<MainPage systemUser={systemUser} signOut={signOut} />}>
          <Route path="" element={<UpcomingContests systemUser={systemUser} />} />
        </Route>
      </Routes> */}
    </div >
  );
}

export default withAuthenticator(App)