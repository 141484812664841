import React, { useEffect, useState } from 'react'
import { getUserUpcomingContests } from '../../../../api/ContestApi'
import UpcomingContest from './UpcomingContest'
import Text from '../../../../styles/Text.module.css'

function UpcomingContests(props) {

  const [contests, setContests] = useState(null)

  useEffect(() => {
    loadContests()
  }, [])

  async function loadContests() {
    try {
      const res = await getUserUpcomingContests()
      if (res) {
        setContests(res.contests)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  if (!contests) {
    return (
      <div>Loading</div>
    )
  }
  else {
    return (
      <div>
        {contests.length < 1 ?
          <div>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.08)", padding: "1rem", borderRadius: ".5rem" }} >
              <div className={Text.textLarge}>No Upcoming Contests</div>
            </div>
          </div> :
          <div>
            {contests && contests.map((contest) =>
              <UpcomingContest key={contest._id} contest={contest} />
            )}
          </div>
        }
      </div>
    )
  }
}
export default UpcomingContests