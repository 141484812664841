import { getEndpoint } from '../helpers/ServiceEndpoint'
import { getAuthHeaders } from '../helpers/Authentication'

export const getUserProfile = async (userId) => {
	let response = await fetch(getEndpoint() + "user-profile/get-user-profile", {
		method: "POST",
		headers: await getAuthHeaders(),
		body: JSON.stringify({
			userId: userId
		})
	})

	response = await response.json()
	return response
}