import React, { useState } from "react"
import Text from '../../../../styles/Text.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getOrdinalSuffix } from "../../../../helpers/Numbers";
import UserProfileModal from "../../user-profile-modal/UserProfileModal";

const ContestUser = (props) => {

	const contestUser = props.contestUser
	const isExpanded = props.isExpanded
	const [isUserProfileOpen, setIsUserProfileOpen] = useState(false)

	const getRank = (rank, isRankTie) => {
		let rankString = ""
		if (isRankTie) rankString += "T"
		rankString += rank
		return rankString + getOrdinalSuffix(rank)
	}

	return (
		<div>
			<div style={{ display: "grid", gridTemplateColumns: "3rem 5rem 5rem 1fr auto", justifyItems: "left", alignItems: "center" }}>
				<div className={Text.labelMedium}>{getRank(contestUser.rank, contestUser.isRankTie)}</div>
				<div className={Text.textMedium} onClick={() => setIsUserProfileOpen(true)}>{contestUser.ticker}</div>
				{contestUser.affiliation ?
					<div className={Text.labelSmall}>{contestUser.affiliation.toUpperCase()}</div> :
					<div></div>
				}
				<div className={contestUser.percentDelta > 0 ? Text.textMediumPositive : Text.textMediumNegative}>{(contestUser.percentDelta * 100).toFixed(2) + "%"}</div>
				{isExpanded ?
					<KeyboardArrowLeftIcon style={{ fontSize: ".8rem", color: "#FFFFFF99" }} /> :
					<KeyboardArrowDownIcon style={{ fontSize: ".8rem", color: "#FFFFFF99" }} />
				}
			</div>
			<UserProfileModal isOpen={isUserProfileOpen} setIsOpen={setIsUserProfileOpen} userProfileId={contestUser.userId} />
		</div>
	)
}

export default ContestUser