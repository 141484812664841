import React, { useEffect, useState } from 'react'
import Text from '../../../styles/Text.module.css'
import { useNavigate } from "react-router-dom"
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import jetImage from '../../../assets/big-jet.png'
import { getUserProfile } from '../../../api/UserProfileApi';


function UserProfileModal(props) {
	const userProfileId = props.userProfileId
	const isOpen = props.isOpen
	const setIsOpen = props.setIsOpen

	const navigate = useNavigate()
	const [userProfile, setUserProfile] = useState(null)

	useEffect(() => {
		loadUserProfile()
	}, [])

	const loadUserProfile = async () => {
		try {
			console.log(userProfileId)
			const res = await getUserProfile(userProfileId)
			if (res) {
				setUserProfile(res.userProfile)
			}
		}
		catch (err) {

		}
	}

	const modalStyle = {
		position: 'absolute',
		left: 0,
		right: 0,
		textAlign: "center",
		width: '100%',
		height: '100%',
		maxWidth: '50rem',
		margin: "auto",
		backgroundColor: "black"
	}


	if (!userProfile)
		<Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="User Profile" aria-describedby="User Profile">
			return <div>Loading</div>
		</Modal>
	else
		return (
			<Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="User Profile" aria-describedby="User Profile">
				<div style={modalStyle}>
					<div style={{ margin: "1rem" }}>
						<div style={{ margin: ".5rem 0", textAlign: "right" }}>
							<CloseIcon onClick={() => setIsOpen(false)} />
						</div>
						<div style={{ margin: "1.5rem 0", textAlign: "left" }}>
							<div>
								<div className={Text.textLarge}>{userProfile.ticker}</div>
							</div>
							<div style={{ margin: "1rem 0" }}>
								<div className={Text.labelSmall}>Member Since</div>
								<div className={Text.textMedium}>{new Date(userProfile.dateAccountCreated).toLocaleDateString("en-US")}</div>
							</div>
							<div style={{ margin: "1rem 0" }}>
								<div className={Text.labelSmall}>Contests Played</div>
								<div className={Text.textMedium}>{userProfile.contests}</div>
							</div>
							<div style={{ margin: "1rem 0" }}>
								<div className={Text.labelSmall}>Average Alpha</div>
								<div className={userProfile.averagePercentDelta >= 0 ? Text.textMediumPositive : Text.textMediumNegative}>{(userProfile.averagePercentDelta * 100).toFixed(2) + "%"}</div>
							</div>
							<div style={{ margin: "1rem 0" }}>
								<div className={Text.labelSmall}>Average Percentile</div>
								<div className={userProfile.averagePercentile >= 0 ? Text.textMediumPositive : Text.textMediumNegative}>{(userProfile.averagePercentile).toFixed(2) + "%"}</div>
							</div>
						</div>
						<div>
							<div style={{ textAlign: "right", overflow: "hidden" }}>
								<img src={jetImage} alt={""} style={{ position: "relative", right: "-8rem" }} />
							</div>
							<div style={{ marginTop: "5rem", textAlign: "right", overflow: "hidden" }}>
								<img src={jetImage} alt={""} style={{ position: "relative", left: "-2rem" }} />
							</div>
						</div>
					</div>
				</div>
			</Modal>



		)
}

export default UserProfileModal