import React from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../../../../styles/Text.module.css'
import Buttons from '../../../../styles/Buttons.module.css'

function UpcomingContest(props) {

	const contest = props.contest
	const navigate = useNavigate()

	function onContestClick() {
		navigate("/joincontestinfo/" + contest._id)
	}

	function onChangeContestPicksClick() {
		navigate("/changecontestpicks/" + contest._id)
	}

	const getContestButton = () => {
		if (contest.arePicksLocked)
			return <div className={Buttons.buttonPrimaryRounded} style={{ width: "10rem", margin: "auto" }}>Picks Locked</div>
		else if (contest.contestUser)
			return <div className={Buttons.buttonPrimaryRounded} style={{ width: "10rem", margin: "auto" }} onClick={onChangeContestPicksClick}>Change Picks</div>
		else
			return <div className={Buttons.buttonPrimaryRounded} style={{ width: "10rem", margin: "auto" }} onClick={onContestClick}>Free Entry</div>

	}

	return (
		<div style={{ backgroundColor: "rgba(255, 255, 255, 0.08)", padding: "1rem", borderRadius: ".5rem" }} >
			<div className={Text.textLarge}>{contest.friendlyName}</div>
			<div style={{ margin: "1rem 0" }}>
				<div className={Text.labelSmall}>{"Starts " + new Date(contest.startDateTime).toLocaleString("en-US")}</div>
				<div className={Text.labelSmall}>{"Ends " + new Date(contest.stopDateTime).toLocaleString("en-US")}</div>
			</div>
			{contest.affiliations &&
				<div style={{ display: "grid", gridTemplateColumns: "5rem 5rem 5rem", justifyContent: "center", alignContent: "center", margin:"1rem 0" }}>
					<div>{contest.affiliations[0].name.toUpperCase()}</div>
					<div>vs</div>
					<div>{contest.affiliations[1].name.toUpperCase()}</div>
				</div>
			}
			<div style={{ margin: "1rem 0" }}>
				<div className={Text.labelSmall}>{"Entries " + contest.participantCount + "/" + contest.participantLimit}</div>
			</div>
			<div style={{ margin: "1rem 0 1rem 0" }}>
				{getContestButton()}
			</div>
		</div>
	)
}

export default UpcomingContest
