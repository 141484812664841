import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const getContest = async (contestId) => {
  let response = await fetch(getEndpoint() + "contest/get-contest", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId
    })
  });

  response = await response.json();
  return response
}

export const getContestToJoin = async (contestId) => {
  let response = await fetch(getEndpoint() + "contest/get-contest-to-join", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId
    })
  });

  response = await response.json();
  return response
}

export const getContestToUpdate = async (contestId) => {
  let response = await fetch(getEndpoint() + "contest/get-contest-to-update", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId
    })
  });

  response = await response.json();
  return response
}

export const getUserUpcomingContests = async () => {
  let response = await fetch(getEndpoint() + "contest/get-user-upcoming-contests", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({})
  });

  response = await response.json();
  return response
}

export const getUserActiveContests = async () => {
  let response = await fetch(getEndpoint() + "contest/get-user-active-contests", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
    })
  });
  response = await response.json();
  return response
}

export const getUserCompletedContests = async () => {
  let response = await fetch(getEndpoint() + "contest/get-user-completed-contests", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
    })
  });

  response = await response.json();
  return response
}

export const getUserActiveContest = async (contestId) => {
  let response = await fetch(getEndpoint() + "contest/get-user-active-contest", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId
    })
  });

  response = await response.json();
  return response
}

export const getUserFirstActiveContest = async (expandedUserId = null) => {
  let response = await fetch(getEndpoint() + "contest/get-user-first-active-contest", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      expandedUserId: expandedUserId
    })
  });

  response = await response.json();
  return response
}

export const getUserCompletedContest = async (contestId) => {
  let response = await fetch(getEndpoint() + "contest/get-user-completed-contest", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId
    })
  });

  response = await response.json();
  return response
}

export const joinContest = async (contestId, picks) => {
  let response = await fetch(getEndpoint() + "contest/join-contest", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId,
      picks: picks
    })
  });

  response = await response.json();
  return response
}

export const changeContestPicks = async (contestId, picks) => {
  let response = await fetch(getEndpoint() + "contest/change-picks", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      contestId: contestId,
      picks: picks
    })
  });

  response = await response.json();
  return response
}
