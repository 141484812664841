import { Auth } from "aws-amplify"

export const getAuthHeaders = async () => {

  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': token
  }
}