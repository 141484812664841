import React, { useEffect, useState } from 'react'
import Text from '../../../styles/Text.module.css'
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import jetImage from '../../../assets/big-jet.png'
import { getUserProfile } from '../../../api/UserProfileApi';

function UserProfile(props) {
	const systemUser = props.systemUser

	const navigate = useNavigate()
	const [userProfile, setUserProfile] = useState(null)

	useEffect(() => {
		loadUserProfile()
	}, [])

	const loadUserProfile = async () => {
		try {
			console.log(systemUser._id)
			const res = await getUserProfile(systemUser._id)
			if (res) {
				setUserProfile(res.userProfile)
			}
		}
		catch (err) {

		}
	}

	const onBack = () => {
		navigate('/contests')
	}


	if (!userProfile)
		return <div>Loading</div>
	else
		return (
			<div>
				<div style={{ margin: ".5rem 0", textAlign: "left" }}>
					<ArrowBackIcon onClick={() => onBack()} />
				</div>
				<div style={{ margin: "1.5rem 0", textAlign: "left" }}>
					<div>
						<div className={Text.textLarge}>{userProfile.ticker}</div>
					</div>
					<div style={{ margin: "1rem 0" }}>
						<div className={Text.labelSmall}>Member Since</div>
						<div className={Text.textMedium}>{new Date(userProfile.dateAccountCreated).toLocaleDateString("en-US")}</div>
					</div>
					<div style={{ margin: "1rem 0" }}>
						<div className={Text.labelSmall}>Contests Played</div>
						<div className={Text.textMedium}>{userProfile.contests}</div>
					</div>
					<div style={{ margin: "1rem 0" }}>
						<div className={Text.labelSmall}>Average Alpha</div>
						<div className={userProfile.averagePercentDelta >= 0 ? Text.textMediumPositive : Text.textMediumNegative}>{(userProfile.averagePercentDelta * 100).toFixed(2) + "%"}</div>
					</div>
					<div style={{ margin: "1rem 0" }}>
						<div className={Text.labelSmall}>Average Percentile</div>
						<div className={userProfile.averagePercentile >= 0 ? Text.textMediumPositive : Text.textMediumNegative}>{(userProfile.averagePercentile).toFixed(2) + "%"}</div>
					</div>
				</div>
				<div>
					<div style={{ textAlign: "right", overflow: "hidden" }}>
						<img src={jetImage} alt={""} style={{ position: "relative", right: "-8rem" }} />
					</div>
				</div>
			</div>
		)
}

export default UserProfile