import React from "react"
import Text from '../../../styles/Text.module.css'
import CrownImage from '../../../assets/crown.png'

const ContestAffiliationDisplay = (props) => {

	const affiliations = props.affiliations
	const contest = props.affiliations

	return (
		<div style={{ display: "grid", gridTemplateColumns: "5rem 5rem 5rem", justifyContent: "center", alignContent: "center", height: "100%" }}>
			<div>
				<Affiliate affiliate={affiliations[0]} />
			</div>
			<div  style={{ marginTop:"2rem" }}>vs</div>
			<Affiliate affiliate={affiliations[1]} />
		</div>
	)
}

const Affiliate = (props) => {
	const affiliate = props.affiliate

	return (
		<div>
			<div style={{ height: "2rem" }}>
				{affiliate.rank === 1 &&
					<img src={CrownImage} style={{ height: "1rem" }} />
				}
			</div>
			<div className={Text.textLarge}>{affiliate.name.toUpperCase()}</div>
			<div className={affiliate.avgPercentDelta > 0 ? Text.textMediumPositive : Text.textMediumNegative}>{(affiliate.avgPercentDelta * 100).toFixed(2) + "%"}</div>
		</div>
	)
}

export default ContestAffiliationDisplay