import React from "react"
import Text from '../../../../styles/Text.module.css'

const ContestUserPicks = (props) => {
	const picks = props.picks
	const align = props.align ? props.align : "left"

	return (
		<div style={{ display: "flex", gap: "1rem", justifyContent: align }}>
			{picks && picks.map((pick) =>
				<div key={pick._id}>
					<div className={Text.labelSmall}>{pick.pickId}</div>
					<div className={pick.percentDelta > 0 ? Text.textSmallPositive : Text.textSmallNegative}>{(pick.percentDelta * 100).toFixed(2) + "%"}</div>
				</div>
			)}
		</div>
	)
}

export default ContestUserPicks