import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const getUser = async () => {
  let response = await fetch(getEndpoint() + "user/get-user", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({})
  });

  response = await response.json();
  return response
}

export const initializeUser = async (firstName, lastName, ticker, affiliation) => {
  let response = await fetch(getEndpoint() + "user/initialize-user", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      ticker: ticker,
      affiliation: affiliation
    })
  });

  response = await response.json();
  return response
}