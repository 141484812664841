import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getContest, getContestToJoin, joinContest } from "../../../../api/ContestApi"
import Text from '../../../../styles/Text.module.css'
import Buttons from '../../../../styles/Buttons.module.css'
import CheckIcon from '@mui/icons-material/Check';
import { getEquities } from "../../../../api/EquitiesApi"

function JoinContestPicks() {

  const navigate = useNavigate()
  const { contestId } = useParams()
  const [contest, setContest] = useState(null)
  const [picks, setPicks] = useState([])
  const [pickOptions, setPickOptions] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    loadContest()
    loadPickOptions()
  }, [])

  async function loadContest() {
    try {
      const res = await getContestToJoin(contestId)
      console.log(res)
      if (res.success) {
        setContest(res.contest)
        return
      }
      else {
        console.log("not success")
        navigate('/')
      }
    }
    catch (err) {
      console.log(err)
      navigate('/')
    }
  }

  async function loadPickOptions() {
    try {
      const res = await getEquities()
      console.log(res)
      if (res) {
        const equities = [...res.equities]
        console.log(equities)
        for (let i = 0; i < equities.length; i++) {
          equities[i].isPicked = false
        }
        setPickOptions(equities)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  async function onJoinClick() {
    setError(null)
    if (canJoinContest()) {
      try {
        const res = await joinContest(contest._id, picks)
        if (res.success) {
          navigate("/joincontestsuccess/" + contest._id)
        }
        else {
          setError(res.error)
          console.log(res.error)
        }
      }
      catch (err) {
        console.log(err)
      }


    }
  }

  function canJoinContest() {
    return picks.length === contest.pickCount
  }

  function onPickOptionClick(pickOption) {

    const pickIndex = picks.findIndex((p) => p.pickId === pickOption.pickId)
    console.log(pickIndex)
    if (pickIndex === -1) {
      if (picks.length >= contest.pickCount)
        return //max picks

      const pickOptionIndex = pickOptions.findIndex((po) => po.pickId === pickOption.pickId)
      const updatedPickOptions = [...pickOptions]
      updatedPickOptions[pickOptionIndex].isPicked = true
      setPickOptions(updatedPickOptions)
      setPicks([...picks, pickOption])
    }
    else {
      //already picked
      const filteredPicks = picks.filter((p) => {
        return p.pickId !== pickOption.pickId
      })

      const pickOptionIndex = pickOptions.findIndex((po) => po.pickId === pickOption.pickId)
      const updatedPickOptions = [...pickOptions]
      updatedPickOptions[pickOptionIndex].isPicked = false
      setPickOptions(updatedPickOptions)
      setPicks(filteredPicks)
    }
  }

  function onPickClick(pick) {
    if (!pick) return
    const filteredPicks = picks.filter((p) => {
      return p.pickId !== pick.pickId
    })

    const pickOptionIndex = pickOptions.findIndex((po) => po.pickId === pick.pickId)
    const updatedPickOptions = [...pickOptions]
    updatedPickOptions[pickOptionIndex].isPicked = false
    setPickOptions(updatedPickOptions)
    setPicks(filteredPicks)
  }



  if (contest) {
    return (
      <div>
        <div style={{ position: "sticky", top: "4rem", background: "#121212" }}>
          <div>
            <div className={Text.textMedium}>Pick your assets</div>
          </div>
          <div>
            <PickBar picks={picks} pickCount={contest.pickCount} onPickClick={onPickClick} />
          </div>
          <div style={{ margin: ".5rem 0" }}>
            <div className={Buttons.buttonPrimary} onClick={onJoinClick}>{canJoinContest() ? "Join Contest" : "Pick " + contest.pickCount}</div>
            <div className={Text.textSmall} style={{ justifyItems: "left" }}>{error}</div>
          </div>
          <div style={{ height: "1rem" }}></div>
        </div>
        <div>
          {pickOptions && pickOptions.map((pickOption) =>
            <PickOption key={pickOption.pickId} pickOption={pickOption} onPickOptionClick={onPickOptionClick} />
          )}
        </div>
        <div style={{ height: "1rem" }}></div>
      </div>
    )
  }
  else {
    return (
      <div>Loading</div>
    )
  }
}

export default JoinContestPicks


function PickBar(props) {
  const pickCount = props.pickCount
  const picks = props.picks
  console.log(picks)
  const onPickClick = props.onPickClick

  function getFormattedPicks() {
    const formattedPicks = []
    for (let i = 0; i < pickCount; i++) {
      let formattedPick
      if (i < picks.length) {
        const pick = picks[i]
        formattedPick = pick
      }
      else {
        formattedPick = null
      }
      formattedPicks.push(formattedPick)
    }
    console.log(formattedPicks)
    return formattedPicks
  }

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      {getFormattedPicks().map((pick, index) =>
        <div key={index}>
          <Pick pick={pick} onPickClick={onPickClick} />
        </div>
      )}
    </div>
  )
}

function Pick(props) {
  const pick = props.pick
  const onPickClick = props.onPickClick

  return (
    <div onClick={() => onPickClick(pick)}>
      <div style={{ width: "3rem", height: "3rem", borderRadius: "50%", backgroundColor: pick ? "#5BD0B3" : "#62626280" }} />
      <div className={Text.labelSmall} style={{ margin: ".25rem 0" }}>
        {pick ?
          <div>{pick.pickId}</div> :
          <div>None</div>
        }
      </div>
    </div>
  )
}

function PickOption(props) {
  const pickOption = props.pickOption
  const onPickOptionClick = props.onPickOptionClick

  const cellRowStyle = {
    display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", justifyItems: "left",
    borderBottom: "1px solid #FFFFFF1F", padding: ".75rem 0"
  }

  return (
    <div onClick={() => onPickOptionClick(pickOption)}>
      <div style={cellRowStyle}>
        <div style={{ textAlign: "left" }}>
          <div className={Text.textMedium}>{pickOption.name}</div>
          <div className={Text.labelSmall}>{pickOption.pickId}</div>
        </div>
        {pickOption.isPicked ?
          <div style={{ width: "2rem", height: "2rem", borderRadius: "50%", backgroundColor: "#5BD0B3", display: "grid", alignItems: "center", justifyItems: "center", color: "#121212" }}>
            <CheckIcon fontSize={"small"} />
          </div> :
          <div style={{ width: "2rem", height: "2rem", borderRadius: "50%", backgroundColor: "#62626280", border: ".1rem white solid" }} />
        }
      </div>
    </div>
  )
}