import React, { useEffect, useState } from "react"
import { getUserFirstActiveContest } from "../../../../api/ContestApi"
import Text from '../../../../styles/Text.module.css'
import { getContestUserPicks } from "../../../../api/ContestUserPickApi"
import ContestUser from "../contest-user-displays/ContestUser"
import ContestUserPicks from "../contest-user-displays/ContestUserPicks"
import { useInterval } from "../../../../hooks/useInterval"
import CurrentUserContestUser from "../contest-user-displays/CurrentUserContestUser"
import Elements from '../../../../styles/Elements.module.css'
import ContestAffiliationDisplay from "../ContestAffiliateDisplay"


function ActiveContest(props) {

	const [contest, setContest] = useState(null)
	const [contestUsers, setContestUsers] = useState(null)
	const [contestUser, setContestUser] = useState(null)
	const [contestUserPicks, setContestUserPicks] = useState(null)
	const [expandedContestUserId, setExpandedContestUserId] = useState(null)
	const [expandedContestUserPicks, setExpandedContestUserPicks] = useState()
	const [isLoadingContest, setIsLoadingContest] = useState(true)
	const [remainingTime, setRemainingTime] = useState()

	useEffect(() => {
		loadContest()
	}, [])

	useInterval(() => {
		if (contest) {
			const currentDateTime = new Date()
			const stopDateTime = new Date(contest.stopDateTime)
			setRemainingTime(stopDateTime - currentDateTime)
		}
	}, 1000)

	useInterval(() => {
		if (contest) {
			updateContest()
		}
	}, 10000)

	async function loadContest() {
		try {
			const res = await getUserFirstActiveContest(expandedContestUserId)
			if (res) {
				setContest(res.contest)
				setContestUsers(res.contestUsers)
				setContestUser(res.contestUser)
				setContestUserPicks(res.contestUserPicks)
				setExpandedContestUserId(null)
				setExpandedContestUserPicks(null)
			}
		}
		catch (err) {
			console.log(err)
		}
		setIsLoadingContest(false)
	}

	async function updateContest() {
		// if (!contest)
		// 	return

		try {
			const res = await getUserFirstActiveContest(expandedContestUserId)
			if (res) {
				setContest(res.contest)
				setContestUsers(res.contestUsers)
				setContestUser(res.contestUser)
				setContestUserPicks(res.contestUserPicks)
				//setExpandedContestUserId(null)
				setExpandedContestUserPicks(res.expandedUserPicks)
			}
		}
		catch (err) {
			console.log(err)
		}
	}

	async function onExpandedContestUserClick(contestUserId) {
		try {
			if (expandedContestUserId === contestUserId) {
				setExpandedContestUserId(null)
				return
			}
			else {
				setExpandedContestUserId(contestUserId)
			}

			const res = await getContestUserPicks(contestUserId)
			if (res) {
				setExpandedContestUserPicks(res.contestUserPicks)
			}
		}
		catch (err) {
			console.log(err)
		}
	}

	function getRemainingTimeString() {
		if (!remainingTime)
			return "-"
		if (remainingTime <= 0)
			return 'contest is finishing...'
		//convert ms to Day Hr Min Sec
		const seconds = remainingTime / 1000
		var d = Math.floor(seconds / (3600 * 24));
		var h = Math.floor(seconds % (3600 * 24) / 3600);
		var m = Math.floor(seconds % 3600 / 60);
		var s = Math.floor(seconds % 60);

		var dDisplay = d + (d == 1 ? " d " : " d ")
		var hDisplay = h + (h == 1 ? " hr " : " hrs ")
		var mDisplay = m + (m == 1 ? " min " : " mins ")
		var sDisplay = s + (s == 1 ? " sec" : " secs")
		//console.log(dDisplay + hDisplay + mDisplay + sDisplay)
		if (d > 0)
			return dDisplay + hDisplay + mDisplay + sDisplay
		else
			return hDisplay + mDisplay + sDisplay
	}

	if (contest && contestUsers && contestUser) {
		return (
			<div>
				<div className={Elements.sticky} style={{ top: "7rem", background: "#121212", height: contest.affiliations ? "15rem" : "9rem" }}>
					<div style={{ margin: "0rem 0", display: "grid", gridTemplateColumns: "auto 1fr", justifyItems: "right", alignItems: "baseline" }}>
						<div style={{ textAlign: "left" }}>
							<div className={Text.textLarge}>{contest.friendlyName}</div>
							<div className={Text.textSmall}>{"30 minute delay"}</div>
						</div>
						<div>{getRemainingTimeString()}</div>
					</div>
					{contest.affiliations &&
						<div style={{ height: "6rem" }}>
							<ContestAffiliationDisplay affiliations={contest.affiliations} contest={contest} />
						</div>
					}
					<div style={{ margin: "1rem 0 0 0" }}>
						<CurrentUserContestUser contestUser={contestUser} contest={contest} />
						<ContestUserPicks picks={contestUserPicks} />
					</div>
					<div style={{ height: "1rem" }}></div>
				</div>
				<div>
					{contestUsers.map((cu) =>
						<div key={cu._id} style={{ borderBottom: "1px solid #FFFFFF1F", padding: ".75rem 0" }} onClick={() => onExpandedContestUserClick(cu._id)}>
							<ContestUser contestUser={cu} isExpanded={expandedContestUserId === cu._id} />
							{cu._id === expandedContestUserId &&
								<div style={{ marginTop: ".5rem" }}>
									<ContestUserPicks picks={expandedContestUserPicks} />
								</div>
							}
						</div>
					)}
				</div>
			</div >
		)
	}
	else if (isLoadingContest) {
		return (
			<div>Loading</div>
		)
	}
	else {
		return (
			<div style={{ backgroundColor: "rgba(255, 255, 255, 0.08)", padding: "1rem", borderRadius: ".5rem" }} >
				<div className={Text.textLarge}>No Active Contest</div>
			</div>
		)
	}
}

export default ActiveContest