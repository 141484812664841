import React, { useEffect, useState } from "react"
import { getUserCompletedContest } from "../../../../api/ContestApi"
import Text from '../../../../styles/Text.module.css'
import { getContestUserPicks } from "../../../../api/ContestUserPickApi"
import ContestUser from "../contest-user-displays/ContestUser"
import ContestUserPicks from "../contest-user-displays/ContestUserPicks"
import CurrentUserContestUser from "../contest-user-displays/CurrentUserContestUser"
import { useNavigate, useParams } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Elements from '../../../../styles/Elements.module.css'
import ContestAffiliationDisplay from "../ContestAffiliateDisplay"

function CompletedContestPage(props) {

	const { contestId } = useParams()
	const [contest, setContest] = useState(null)
	const [contestUsers, setContestUsers] = useState(null)
	const [contestUser, setContestUser] = useState(null)
	const [contestUserPicks, setContestUserPicks] = useState(null)
	const [expandedContestUserId, setExpandedContestUserId] = useState(null)
	const [expandedContestUserPicks, setExpandedContestUserPicks] = useState()
	const [isLoadingContest, setIsLoadingContest] = useState(true)
	const navigate = useNavigate()

	useEffect(() => {
		loadContest()
	}, [])

	async function loadContest() {
		try {
			const res = await getUserCompletedContest(contestId)
			console.log(res)
			if (res) {
				setContest(res.contest)
				setContestUsers(res.contestUsers)
				setContestUser(res.contestUser)
				setContestUserPicks(res.contestUserPicks)
				setExpandedContestUserId(null)
				setExpandedContestUserPicks(null)
			}
		}
		catch (err) {
			console.log(err)
		}
		setIsLoadingContest(false)
	}

	async function onExpandedContestUserClick(contestUserId) {
		try {
			if (expandedContestUserId === contestUserId) {
				setExpandedContestUserId(null)
				return
			}
			else {
				setExpandedContestUserId(contestUserId)
			}

			const res = await getContestUserPicks(contestUserId)
			console.log(res)
			if (res) {
				setExpandedContestUserPicks(res.contestUserPicks)
			}
		}
		catch (err) {
			console.log(err)
		}
	}

	const onBack = () => {
		navigate('/contests/completed')
	}

	const getAffiliateWinnerText = () => {
		if (contest.affiliations[0].rank === 1 && contest.affiliations[1].rank === 1) {
			return contest.affiliations[0].name.toUpperCase() + " and " + contest.affiliations[1].name.toUpperCase() + " tied!"
		}
		else if (contest.affiliations[0].rank === 1) {
			return contest.affiliations[0].name.toUpperCase() + " Wins!"
		}
		else {
			return contest.affiliations[1].name.toUpperCase() + " Wins!"
		}
	}

	if (contest && contestUsers && contestUser) {
		return (
			<div>
				<div className={Elements.sticky} style={{ top: "4rem", background: "#121212", height: contest.affiliations ? "15rem" : "9rem" }}>
					<div style={{ margin: ".5rem 0", display: "grid", gridTemplateColumns: "auto 1fr 2rem", justifyItems: "center", alignItems: "center" }}>
						<ArrowBackIcon onClick={() => onBack()} />
						<div className={Text.textLarge}>{contest.friendlyName}</div>
						<div></div>
					</div>
					{contest.affiliations &&
						<div style={{ height: "6rem" }}>
							<ContestAffiliationDisplay affiliations={contest.affiliations} contest={contest} />
							<div className={Text.textMedium}>{getAffiliateWinnerText()}</div>
						</div>
					}
					<div style={{ margin: "1.5rem 0" }}>
						<CurrentUserContestUser contestUser={contestUser} contest={contest} />
						<div style={{ marginTop: ".5rem" }}>
							<ContestUserPicks picks={contestUserPicks} />
						</div>
					</div>
				</div>
				<div>
					{contestUsers.map((cu) =>
						<div key={cu._id} style={{ borderBottom: "1px solid #FFFFFF1F", padding: ".75rem 0" }} onClick={() => onExpandedContestUserClick(cu._id)}>
							<ContestUser contestUser={cu} isExpanded={expandedContestUserId === cu._id} />
							{cu._id === expandedContestUserId &&
								<ContestUserPicks picks={expandedContestUserPicks} />
							}
						</div>
					)}
				</div>
			</div >
		)
	}
	else if (isLoadingContest) {
		return (
			<div>Loading</div>
		)
	}
	else {
		return (
			<div style={{ backgroundColor: "rgba(255, 255, 255, 0.08)", padding: "1rem", borderRadius: ".5rem" }} >
				<div className={Text.textLarge}>No Completed Contest</div>
			</div>
		)
	}
}

export default CompletedContestPage