import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';


export const getEquities = async () => {
  let response = await fetch(getEndpoint() + "equity/get-equities", {
    method: "POST",
    headers: await getAuthHeaders(),
    body: JSON.stringify({})
  });

  response = await response.json();
  return response
}
