import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import ContestNav from '../contest-nav/ContestNav'
import Elements from '../../../styles/Elements.module.css'


function ContestsLayout(props) {

  return (
    <>
      <div className={Elements.sticky} style={{ top: "4rem", background: "#121212", height: "3rem" }}>
        <ContestNav />
      </div>
      <Outlet />
    </>
  )
}

export default ContestsLayout