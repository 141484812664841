import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getContest } from "../../../../api/ContestApi"
import Text from '../../../../styles/Text.module.css'
import Buttons from '../../../../styles/Buttons.module.css'

function JoinContestInfo() {

    const navigate = useNavigate()
    const { contestId } = useParams()
    const [contest, setContest] = useState(null)


    useEffect(() => {
        loadContest()
    }, [])

    async function loadContest() {
        try {
            const res = await getContest(contestId)
            console.log(res)
            if (res) {
                setContest(res.contest)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    function onJoinClick() {
        navigate("/joincontestpicks/" + contest._id)
    }

    const cellRowStyle = {
        display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", justifyItems: "left",
        borderBottom: "1px solid #FFFFFF1F", padding: ".75rem 0"
    }

    if (contest) {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ flexGrow: "1" }}>
                    <div style={{ margin: ".5rem 0", display: "grid", gridTemplateColumns: "auto 1fr auto" }}>
                        <div></div>
                        <div className={Text.textMedium}>{contest.friendlyName}</div>
                        <div></div>
                    </div>
                    <div style={{ margin: "1rem 0", textAlign: "left" }}>
                        <div className={Text.textMedium}>Choose {contest.pickCount} stocks for your portfolio. The gunner with the highest % net alpha at the end of the contest is the Top Gun Investor.</div>
                    </div>
                    <div>
                        <div style={cellRowStyle}>
                            <div className={Text.labelMedium}>Entry Fee</div>
                            <div className={Text.textMedium}>{"Free"}</div>
                        </div>
                        <div style={cellRowStyle}>
                            <div className={Text.labelMedium}>Entries</div>
                            <div className={Text.textMedium}>{contest.participantCount + "/" + contest.participantLimit}</div>
                        </div>
                        <div style={cellRowStyle}>
                            <div className={Text.labelMedium}>Total Prize Pool</div>
                            <div className={Text.textMedium}>{"-"}</div>
                        </div>
                        <div style={cellRowStyle}>
                            <div className={Text.labelMedium}>Starts</div>
                            <div className={Text.textMedium}>{new Date(contest.startDateTime).toLocaleString("en-US")}</div>
                        </div>
                        <div style={cellRowStyle}>
                            <div className={Text.labelMedium}>Ends</div>
                            <div className={Text.textMedium}>{new Date(contest.stopDateTime).toLocaleString("en-US")}</div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: "2rem 0" }}>
                    <div className={Buttons.buttonPrimary} onClick={onJoinClick}>Continue</div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>Loading</div>
        )
    }
}

export default JoinContestInfo