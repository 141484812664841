import React from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

function ContestNav(props) {

  const navigate = useNavigate()

  const isLive = useMatch('/contests/active')
  const isCompleted = useMatch('/contests/completed')
  const isUpcoming = (!isLive && !isCompleted)

  function onLiveClick() {
    navigate("/contests/active")
  }

  function onUpcomingClick() {
    navigate("/contests/upcoming")
  }

  function onCompletedClick() {
    navigate("/contests/completed")
  }

  const selectedStyle = {
    color: "#FFFFFFDE",
    borderBottom: ".15rem solid white"
  }

  const deselectedStyle = {
    color: "#FFFFFF99",
    borderBottom: ".2rem solid transparent"
  }

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", cursor: "pointer", height: "2rem", maxWidth: '30rem' }}>
        <div style={isUpcoming ? selectedStyle : deselectedStyle} onClick={onUpcomingClick}>Upcoming</div>
        <div style={isLive ? selectedStyle : deselectedStyle} onClick={onLiveClick}>Live</div>
        <div style={isCompleted ? selectedStyle : deselectedStyle} onClick={onCompletedClick}>Completed</div>
      </div>
    </div>
  )
}

export default ContestNav