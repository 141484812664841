import React, { useState } from 'react'
import Buttons from '../../../styles/Buttons.module.css'
import jetImage from '../../../assets/jet.png'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'
import { Modal } from '@mui/material'
import MenuModal from '../menu-modal/MenuModal'

function Header(props) {
  const systemUser = props.systemUser
  const signOut = props.signOut

  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "35px 1fr 35px", alignItems: "center", height: "4rem" }}>
        <div></div>
        <div>
          <img src={jetImage} alt={""} />
        </div>
        <div style={{ textAlign: "center" }} onClick={() => setIsMenuOpen(true)}>
          <MenuIcon fontSize="large" />
        </div>
      </div>
      <MenuModal isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} signOut={signOut} />
    </>
  )
}

export default Header